<template>
  <div>
    <div v-if="allDataAvailable">
      <featuredParent v-if="categoryScreens[0] && (categoryScreens[0].sectionType == 'FEATURED' || categoryScreens[0].listType == 'CAROUSEL')" :featuredScreen="categoryScreens[0]" :screenName="screens.id"></featuredParent>

      <div v-for="(item, index) in categoryScreens" :key="index">
        <slider v-if="(item.sectionType != 'FEATURED' && item.listType != 'CAROUSEL')" :screen="item" :key="title" :id="index" :playerInstance="playerInstance" :screenName="screens.id" >
        </slider>
      </div>
    </div>
    <div class="no-data" v-else>
        <div>
          <div class="center-div">
            <img 
          class="no-data-img"
          src="@/assets/icons/ic_empty.png"/>
          </div>

      <p class="no-data-text" >{{$t('No contents available')}}</p>
        </div>

    </div>

    <intersect :key="title" @enter="inters">
      <div></div>
    </intersect>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { eventBus } from "@/eventBus";
import Intersect from "vue-intersect";
import Utility from "@/mixins/Utility.js";

export default {
  props: {
    menuDetail: {
      type: Object,
    },
    section: {
      type: [Object, String],
    },
    playerInstance: {
      type: Object,
    },
  },
  data() {
    return {
      loader: true,
      screenContent: [],
      errorMessage: null,
      localDisplayLang: null,
      page: 1,
      isScrollable: false,
      isMpegRequired: false,
      sectionScreen: null,
      menu: null,
      screens: null,
      categoryScreens: [],
      title: null,
      lowCounter: 0,
      highCounter: 1,
      multiplyValue: 3,
      screensList: [],
      allDataAvailable: true,
      categoryDataTracker: [],
    };
  },
  computed: {
    ...mapGetters(["appConfig"]),
  },
  watch: {
    $route() {
      this.screenContent = [];
      this.screensList = [];
      this.page = 1;
      this.title = this.$router.currentRoute.params.section.toLowerCase();
      this.lowCounter = 0;
      this.highCounter = 1;
      this.multiplyValue = 3;
      this.categoryScreens = [];

      this.getContentScreen();
      this.actMetaData();
      // Refresh All the Contents

    },
    categoryDataTracker(data) {
      let allDataAvailable = false;
      for (let i = 0; i < this.categoryDataTracker.length; i++) {
        let obj = this.categoryDataTracker[i];
        if (obj?.is_data_available) {
          allDataAvailable = true;
        }
      }
      this.allDataAvailable = allDataAvailable;
      
    },
    section(data) {
      this.allDataAvailable = true;
    },
    menuDetail(val) {
      if (val) {
        this.loadTemplate(val);
      }
    },

  },
  created() {

    // The Section Screen
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    this.getContentScreen();

    this.title = this.$router.currentRoute.params.section;
  },
  mounted() {
    this.actMetaData();
    let categoryDataLength = this.categoryScreens.length;
    let categoryDataTrackerObj = [];
    for (let i = 0; i < categoryDataLength; i++) {
      let obj = { "index": i, "is_data_available": true }
      categoryDataTrackerObj.push(obj);
    }
    this.categoryDataTracker = categoryDataTrackerObj;
    eventBus.$on("on-data-callback", (data) => {

      let categoryDataTrackerObj = this.categoryDataTracker;
      categoryDataTrackerObj[data.index] = data;
      this.categoryDataTracker = [...categoryDataTrackerObj];
    
    });
  },
  methods: {
    actMetaData() {
      let staticPage = this.metaData();
      let sectionName = this.$router.currentRoute.path;
      for (let singlePage in staticPage) {
            if (sectionName.includes(singlePage)) {
              window.document.title = staticPage[singlePage].title;
              document.getElementsByTagName('meta')["keywords"].setAttribute('content', staticPage[singlePage].keywords);
              document.getElementsByTagName('meta')["description"].setAttribute('content', staticPage[singlePage].description);
              document.querySelector('meta[property="og\\:title"]').setAttribute('content', staticPage[singlePage].title);
              document.querySelector('meta[name="twitter\\:title"]').setAttribute('content', staticPage[singlePage].title);
              document.querySelector('meta[property="og\\:description"]').setAttribute('content', staticPage[singlePage].description);
              document.querySelector('meta[name="twitter\\:description"]').setAttribute('content', staticPage[singlePage].description);
            }
      }
  },
    inters() {
      this.callNextSetCategories();
    },
    callNextSetCategories() {
      let nextSetData = this.getSlicedSections(this.screensList);
      this.passDataToCarousal(nextSetData);
    },
    loadTemplate(menu) {
      if (menu) {
        this.menu = menu;
        let index = this.appConfig.screens.findIndex((element) => {
          return this.menu === element.id;
        });

        if (index > -1) {
          this.screens = this.appConfig.screens[index];
        }
      }
    },

    //to get the screens from appconfig object.
    checkMpegRequired() {
      let isMpegRequired = false;
      let videoTag = document.createElement("video");
      let platformOs = navigator.platform;

      if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          isMpegRequired = true;
        }
      }

      return isMpegRequired;
    },
    getContentScreenDecking () {
      let deckingconfig = localStorage.getItem("appDeckingConfig") ? JSON.parse(localStorage.getItem("appDeckingConfig")) : '';

      let allMenuList = [...deckingconfig.screens]

      let currentMenu = "";
      if (this.$router.currentRoute.params.section) {
        currentMenu = this.$router.currentRoute.params.section 
      } else if (this.$router.currentRoute.path.includes('-short-')) {
        currentMenu = 'shortbytes'
      } else if (this.$router.currentRoute.path.includes('-devotional-')) {
        currentMenu = 'devotional'
      } else if (this.$router.currentRoute.path.includes('-video-songs')) {
        currentMenu = 'music'
      }
      allMenuList.forEach((ele) => {
        if ((ele.id.replaceAll(' ','')).toLowerCase().includes((currentMenu).toLowerCase())) {
          this.sectionScreen = ele.id;
        }
      });

         if (this.sectionScreen) {
        this.menu = this.sectionScreen;
        let index = allMenuList.findIndex((element) => {
          return this.menu === element.id;
        });

        if (index > -1) {
          this.screens = allMenuList[index];
        }
      }
      for (let singleItem of this.screens.sections) {
        if (singleItem.status == 'ACTIVE') {
          this.screensList.push(singleItem) 
        }
        
      }

      // this.screensList = this.screens.sections;

      let finalSectionsData = this.getSlicedSections(this.screensList);
      this.passDataToCarousal(finalSectionsData);

    },
    getContentScreen() {
      if (this.appConfig.featureEnabled && this.appConfig.featureEnabled.isDeckingEnabled) {
        this.getContentScreenDecking();
        return;
      }

      let allMenuList = [...this.appConfig.menu, ...this.appConfig.moreMenuOptions]
      allMenuList.forEach((ele) => {
        if (ele.id.toLowerCase() === this.$router.currentRoute.params.section) {
          this.sectionScreen = ele.id;
        }
      });

      this.loadTemplate(this.sectionScreen);

      this.screensList = this.screens.sections;
      let finalSectionsData = this.getSlicedSections(this.screensList);
      this.passDataToCarousal(finalSectionsData);
    },
    getSlicedSections(sections) {
      let finalSections = sections.slice(
        this.lowCounter * this.multiplyValue,
        this.highCounter * this.multiplyValue
      );
      this.lowCounter = this.lowCounter + 1;
      this.highCounter = this.highCounter + 1;

      return finalSections;
    },

    passDataToCarousal(data) {
      data.forEach((element) => {
        this.categoryScreens.push(element);
      });
    },
    getAllContents(isMpegRequired) {
      let payload = {
        endpoint: this.screen.endpoint,
        params: this.screen.parameters,
      };
      payload.params.displaylanguage = this.localDisplayLang;
      payload.params.page = this.page;

      if (isMpegRequired == true) {
        payload.params.mpegtspackage = "YES";
      }

      let subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails");

      if (subscriberProfileDetails) {
        subscriberProfileDetails = JSON.parse(subscriberProfileDetails);
      }

      if (subscriberProfileDetails && subscriberProfileDetails.kidsmode === "YES") {
        payload.params.genre = "Kids";
      }

      eventBus.$emit("grid-see-all-data", payload);
    },
    initialRoute() {
			this.$router.replace({ name: "Home", params: { lang: this.$i18n.locale == 'ara' ? 'ar' : 'en'}});
    },
    loadMoreContents() {
      this.page = this.page + 1;
      this.getAllContents(this.isMpegRequired);
    },
  },
  components: {
    Intersect,
    slider: () => import(/* webpackChunkName: "carousel" */ "@/components/categorySlider/slider.vue"),
    featuredParent: () =>
      import(
        /* webpackChunkName: "featured" */ "@/components/Carousels/featuredParent.vue"
      ),
  },
  beforeDestroy() {
    this.screenContent = [];
    eventBus.$off("check-for-series-route");
    eventBus.$off("get-seeAll-data");
  },
  mixins: [Utility],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./sectionWiseScreen.scss"
</style>
